<template>
  <section class="fontStandard" v-show="!rgpdRule">
    <div id="CookiesContainer">
      <p class="CookiesInfo">
        Ce site Web utilise des cookies Les cookies nous permettent de
        personnaliser le contenu et les annonces, d'offrir des fonctionnalités
        relatives aux médias sociaux et d'analyser notre trafic. Nous partageons
        également des informations sur l'utilisation de notre site avec nos
        partenaires de médias sociaux, de publicité et d'analyse, qui peuvent
        combiner celles-ci avec d'autres informations que vous leur avez
        fournies ou qu'ils ont collectées lors de votre utilisation de leurs
        services
      </p>
      <button class="accept" @click="accept">Je comprends et j'accepte</button>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      rgpd_accepted: false,
    };
  },
  mounted() {
    this.rgpd_accepted = localStorage.getItem("rgpd_rules");
  },
  computed: {
    rgpdRule() {
      return this.rgpd_accepted;
    },
  },
  methods: {
    accept() {
      this.rgpd_accepted = "1";
      localStorage.setItem("rgpd_rules", this.rgpd_accepted);
    },
  },
};
</script>

<style scoped>
@-webkit-keyframes slideMenuOpen {
  from {
    margin-bottom: -100%;
  }
  to {
    margin-bottom: 0;
  }
}
@keyframes slideMenuOpen {
  from {
    margin-bottom: -100%;
  }
  to {
    margin-bottom: 0;
  }
}
@-webkit-keyframes slideMenuClose {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: -100%;
  }
}
@keyframes slideMenuClose {
  from {
    margin-bottom: 0;
  }
  to {
    margin-bottom: -100%;
  }
}
section {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(22, 22, 22, 0.9);
  color: whitesmoke;
  padding: 0.5em 0;
  transition: 1s;
  z-index: 9999;
}
.acceptedStateRgpd {
  display: none;
}

#CookiesContainer {
  margin: auto;
  width: 50%;
  text-align: center;
  animation: 0.5s 1 normal slideMenuOpen;
}

#CookiesContainer .CookiesInfo {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 0.8em;
}

#CookiesContainer button {
  border: 0;
  padding: 0.25em 1em;
  border-radius: 0.25em;
  font-size: 1em;
  text-align: center;
  box-shadow: 2px 2px rgba(51, 51, 51, 0.7);
  font-weight: bold;
  transition: 0.5s;
}

#CookiesContainer .accept {
  background-color: #18915c;
  color: whitesmoke;
  text-shadow: 1px 1px 1px #333;
}
#CookiesContainer .decline {
  background-color: darkred;
  color: whitesmoke;
  text-shadow: 1px 1px 1px #333;
}
#CookiesContainer .accept:hover,
#CookiesContainer .decline:hover {
  background-color: darkslategray;
  transition: 0.5s;
}

/*
 *************************  Media Queries *************************
  color: gestion de la couleur (en bits/pixel).
  height: hauteur de la zone d'affichage (fenêtre).
  width: largeur de la zone d'affichage (fenêtre).
  device-height: hauteur du périphérique.
  device-width: largeur du périphérique.
  orientation: orientation du périphérique (portrait ou paysage).
  media: type d'écran de sortie. Quelques-unes des valeurs possibles :
    screen: écran « classique » ;
    handheld: périphérique mobile ;
    print: impression ;
    tv: télévision ;
    projection: projecteur ;
    all: tous les types d'écran. 
*/

@media screen and (max-width: 700px) {
  section {
    background-color: rgba(33, 33, 33, 1);
  }
  #CookiesContainer {
    padding: 0 1em;
    width: 100%;
  }
}
</style>
